import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Script from "react-inline-script"
import CaseStudySection from "../components/header/header-casestudy-section"
import Cases from "../components/cases"
import { StaticImage } from "gatsby-plugin-image"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const ThankYouPage = props => {
  const siteTitle = "Thank You"
  const siteDescription = props.data.site.siteMetadata.description

  const { data } = props

  return (
    <Layout location={props.location} title={`Thank You!`}>
      <SEO title={siteTitle} description={siteDescription} />
      <Script
        type="text/javascript"
        async
        defer
        src="https://www.googletagmanager.com/gtag/js?id=AW-838287664"
      ></Script>
      <Script type="text/javascript" async defer>
        window.dataLayer = window.dataLayer || []; function gtag()
        {`window.dataLayer.push(arguments);`}ß gtag('js', new Date());
        gtag('config', 'AW-838287664');
      </Script>
      {/* Event snippet for Contact Us Form Submission conversion page  */}
      <Script>
        {`gtag('event', 'conversion', {'send_to': 'AW-838287664/UuvPCLTUgLwZELCC3Y8D'});`}
      </Script>

      <section className="relative">
        <div className="hero-banner knowledge-base-banner">
          <div className="container">
            <div className="hero-banner-content text-center">
              <h1 className="page-title font-weight-bold text-white mt-2">
                Thank you! We've received your message and will be in touch
                soon.
              </h1>
              <div className="d-flex mt-2 justify-content-center">
                <Link aria-label="link" to="/" className="btn btn-primary mt-3">
                  RETURN HOME
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CaseStudySection className="relative" />
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
    </Layout>
  )
}

export default ThankYouPage

export const ThankYouAgencyQuery = graphql`
  query ThankYouAgencyQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
